<template>
  <div class="my-product-warp">
    <!-- <div class="product-list"></div> -->
    <div class="tabs">
      <div v-for="item, index in tabs" :key="item.id" class="tabs-item" :class="{'current': item.id == current}" @click.stop="handleClick(item)">
        <BpyIcon class="pro" type="filled-card-three" />
        <span>{{ item.name || '产品信息' }}</span>
        <div class="del-item" @click.stop="delItem(item, index)">
          <BpyIcon :size="16" type="outline-delete" />
        </div>
      </div>
      <div v-if="tabs.length < 5" class="add-product" @click="addTab"><BpyIcon color="#8c8e8f" type="filled-add" />添加产品信息</div>
    </div>
    <div v-if="current" class="content">
      <div class="current-pro">
        <div class="form-item item-center">
          <div class="label">产品简称</div>
          <div class="item-con" :style="{pointerEvents: editVal.is_edit ? undefined : 'none'}">
            <a-input v-model="editVal.name" :maxLength="20"/>
            <div class="limit" :class="{ 'max-count': editVal.name && editVal.name.length === 20}">{{editVal.name.length}}/20</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label">产品简介</div>
          <div class="item-con" :style="{pointerEvents: editVal.is_edit ? undefined : 'none'}">
            <div class="editor-con">
              <bpy-editor ref="bpy-editor" :value="editVal.content" @editorChange="editorChange" />
            </div>
          </div>
        </div>
        <div class="btn-con">
          <span v-if="!editVal.is_edit" class="save" @click="editVal.is_edit = true">编辑</span>
          <template v-else>
            <span class="save" @click="handleSubmit">保存</span>
            <span v-if="editVal.id !== 'add'" class="cancel" @click="handleCancel">取消</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BpyEditor from '../../components/BpyEditor'
export default {
  data () {
    return {
      current: undefined,
      tabs: [],
      editVal: {}
    }
  },
  computed: {
    currentVal () {
      return this.tabs.find(item => item.id === this.current) || { name: ''}
    }
  },
  components: { BpyEditor },
  created () {
    this.getlist()
  },
  methods: {
    saveConfirm () {
      if (!this.editVal.is_edit) {
        return true
      }
      return new Promise((resolve) => {
        this.$confirm({
          title: '保存提示',
          content: '当前产品信息还未保存，是否保存?',
          onOk: async () => {
            resolve(await this.handleSubmit())
          },
          onCancel: () => {
            resolve(false)
          }
        })
      })
    },
    async addTab () {
      let valid = await this.saveConfirm()
      if (!valid) return
      this.tabs.push(
        {
          id: 'add',
          name: '',
          content: [],
          is_edit: true
        }
      )
      this.current = 'add'
    },
    delItem (item, index) {
      this.$confirm({
        title: '删除提示',
        content: '删除后数据不可恢复，确定要删除吗？',
        onOk: async () => {
          if (item.id === 'add') {
            this.tabs.splice(index, 1)
          } else {
            let res = await AuthService.userProductDelete({ id: item.id })
            if (res) {
              this.tabs.splice(index, 1)
            }
          }
          if (item.id === this.current) {
            this.current = this.tabs[index]?.id || this.tabs.at(-1)?.id || undefined
          }
        },
        onCancel: () => {
        }
      })
    },
    async handleClick (item) {
      if (this.editVal.id === 'add' || this.editVal.is_edit) {
        let valid = await this.saveConfirm()
        if (!valid) {
          return
        }
      }
      this.current = item.id
    },
    editorChange (content) {
      this.editVal.content = content
    },
    async handleSubmit () {
      const params = {
        id: this.editVal.id,
        name: this.editVal.name,
        content: JSON.stringify(this.editVal.content),
      }
      if (this.editVal.id === 'add') {
        delete params.id
      }
      if (this.editVal.name.trim().length === 0) {
        this.$message.error('请填写产品简称')
        return false
      }
      if (this.editVal.content.length === 0) {
        this.$message.error('请填写产品简介')
        return false
      }
      if (this.editVal.content.length === 1 && (this.editVal.content[0].content.length == 0 || this.editVal.content[0].content[0].content.length == 0)) {
        this.$message.error('请填写产品简介')
        return false
      }
      let res = await AuthService.userProductCreateOrEdit(params)
      if (!params.id) { //新增重新设置current
        this.current = res.id
      }
      this.$message.success(`${params.id ? '编辑' : '新增'}成功`)
      this.getlist()
      return res
    },
    handleCancel (e) {
      this.editVal = {...this.currentVal}
      this.$refs['bpy-editor'].setContnet(this.editVal.content)
    },
    async getlist () {
      let res = await AuthService.userProductList({ without_page: 1 })
      if (res.length > 0) {
        this.tabs = res.map(val => {
          try {
            val.content = JSON.parse(val.content)
          } catch {
            val.content = []
          }
          val.is_edit = false
          return val
        })
        if (!this.current) {
          this.current = this.tabs[0]?.id || undefined
        }
      }
    }
  },
  watch: {
    currentVal: {
      handler (val) {
        this.editVal = {...val}
      }
    },
    editVal: {
      handler (val) {
        this.$nextTick(() => {
          this.$refs['bpy-editor'].setContnet(val.content)
        })
      }
    }
  }
}
</script>

<style>

</style>