<template>
  <div class="editor-box"/>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      editor: null
    }
  },
  mounted () {
    this.init(this.value)
  },
  methods: {
    init (content = []) {
      const _this = this
      const BpyEditor = window.BpyEditor
      this.editor = new BpyEditor({ // eslint-disable-line
        el: document.querySelector('.editor-box'),
        content,
        uploadImage: async () => { // eslint-disable-line
          return await this.uploadFn('image')
        },
        uploadVideo: async () => { // eslint-disable-line
          return await this.uploadFn('video')
        },
        onChange (res) {
          res.forEach((item, index) => {
            item.index = index
          })
          _this.$emit('editorChange', res)
        }
      })
      this.$nextTick(() => {
        this.$el.querySelectorAll('.bpy-editor .input-wrap').forEach(el => el.blur())
      })
    },
    uploadFn (type = 'image') {
      return new Promise((resolve) => {
        const fileParams = { type, check_type: 2, max_size: 20 }
        Upload2Oss(fileParams, (res) => {
          if (res && res[0]) {
            resolve(res[0].url || res[0].hash.url)
          }
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    setContnet (val = []) {
      this.editor.$update(val)
      // this.init(val)
    }
  }
}
</script>
